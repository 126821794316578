<script setup>
import {
  Dialog,
  DialogPanel,
  Disclosure,
  Menu,
  MenuItems,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { XMarkIcon } from "@heroicons/vue/24/outline";
import { FunnelIcon } from "@heroicons/vue/20/solid";

const props = defineProps({
  coupons: { type: Array, required: true },
  categories: { type: Array, required: true },
});

// const sortOptions = [
//   { name: "Most Popular", href: "#", current: true },
//   { name: "Best Rating", href: "#", current: false },
//   { name: "Newest", href: "#", current: false },
//   { name: "Price: Low to High", href: "#", current: false },
//   { name: "Price: High to Low", href: "#", current: false },
// ];

const route = useRoute();
const router = useRouter();

const state = reactive({
  categoryList: props.categories.map((category) => {
    return {
      label: category.name,
      value: category.category_id.toString(),
      checked: false,
    };
  }),
});

const filters = [
  {
    id: "category",
    name: "Categorías",
    options: state.categoryList,
  },
];

const selectedCategories = ref([]);

const subCategories = [];
const mobileFiltersOpen = ref(false);

watch(selectedCategories, (newVal) => {
  router.push({ path: route.path, query: { ...route.query, filters: newVal } });
});

watch(
  () => route.query.filters,
  () => {
    const filtersFromUrl = Array.isArray(route.query.filters)
      ? route.query.filters
      : route.query.filters
      ? [route.query.filters]
      : [];

    filters[0].options.forEach((option) => {
      option.checked = filtersFromUrl.includes(option.value);
      if (option.checked && !selectedCategories.value.includes(option.value)) {
        selectedCategories.value.push(option.value);
      } else if (!option.checked) {
        const index = selectedCategories.value.indexOf(option.value);
        if (index > -1) {
          selectedCategories.value.splice(index, 1);
        }
      }
    });
  },
  { immediate: true },
);

onMounted(() => {
  const filtersFromUrl = Array.isArray(route.query.filters)
    ? route.query.filters
    : route.query.filters
    ? [route.query.filters]
    : [];

  filters[0].options.forEach((option) => {
    option.checked = filtersFromUrl.includes(option.value);
    if (option.checked && !selectedCategories.value.includes(option.value)) {
      selectedCategories.value.push(option.value);
    }
  });
});
</script>
<template>
  <section id="cuponera" class="bg-white">
    <div>
      <!-- Mobile filter dialog -->
      <TransitionRoot as="template" :show="mobileFiltersOpen">
        <Dialog
          as="div"
          class="relative z-40 lg:hidden"
          @close="mobileFiltersOpen = false"
        >
          <TransitionChild
            as="template"
            enter="transition-opacity ease-linear duration-300"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <div class="fixed inset-0 bg-black bg-opacity-25" />
          </TransitionChild>

          <div class="fixed inset-0 z-40 flex">
            <TransitionChild
              as="template"
              enter="transition ease-in-out duration-300 transform"
              enter-from="translate-x-full"
              enter-to="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leave-from="translate-x-0"
              leave-to="translate-x-full"
            >
              <DialogPanel
                class="relative ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto bg-slate-50 py-4 pb-12 shadow-xl"
              >
                <div class="flex items-center justify-between px-4">
                  <!-- <h2 class="text-lg font-medium text-gray-900">Filters</h2> -->
                  <div></div>
                  <button
                    type="button"
                    class="-mr-2 flex h-10 w-10 items-center justify-center rounded-md bg-white p-2 text-gray-400"
                    @click="mobileFiltersOpen = false"
                  >
                    <span class="sr-only">Close menu</span>
                    <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>

                <!-- Filters -->
                <form class="">
                  <h3 class="sr-only">Categories</h3>
                  <ul role="list" class="px-2 font-medium text-gray-900">
                    <li v-for="category in subCategories" :key="category.name">
                      <a :href="category.href" class="block px-2 py-3">{{
                        category.name
                      }}</a>
                    </li>
                  </ul>

                  <Disclosure
                    v-for="section in filters"
                    :key="section.id"
                    v-slot="{}"
                    as="div"
                    class="px-4"
                  >
                    <h3 class="-mx-2 -my-3 flow-root">
                      <div
                        class="flex w-full items-center justify-between px-2 py-3 text-gray-400 hover:text-gray-500"
                      >
                        <span class="font-medium text-gray-900">{{
                          section.name
                        }}</span>
                        <!-- <span class="ml-6 flex items-center">
                           <PlusIcon
                            v-if="true"
                            class="h-5 w-5"
                            aria-hidden="true"
                          />
                          <MinusIcon
                            v-else
                            class="h-5 w-5"
                            aria-hidden="true"
                          /> 
                        </span> -->
                      </div>
                    </h3>
                    <div class="pt-6">
                      <div class="space-y-4">
                        <div
                          v-for="(option, optionIdx) in section.options"
                          :key="option.value"
                          class="flex items-center"
                        >
                          <input
                            :id="`filter-mobile-${section.id}-${optionIdx}`"
                            v-model="selectedCategories"
                            :name="`${section.id}[]`"
                            :value="option.value"
                            type="checkbox"
                            :checked="option.checked"
                            class="h-4 w-4 rounded border-gray-300 text-custom-calypso-fe3 focus:ring-custom-calypso-fe3"
                          />
                          <label
                            :for="`filter-mobile-${section.id}-${optionIdx}`"
                            class="ml-3 min-w-0 flex-1 text-gray-500"
                            >{{ option.label }}</label
                          >
                        </div>
                      </div>
                    </div>
                  </Disclosure>
                </form>
              </DialogPanel>
            </TransitionChild>
          </div>
        </Dialog>
      </TransitionRoot>

      <main class="mx-auto px-4 sm:px-6 lg:px-8">
        <div
          class="flex items-baseline justify-between border-b border-slate-100 pb-4 pt-12"
        >
          <h1
            class="font-montserrat text-4xl font-bold tracking-tight text-gray-700"
          >
            Cuponera
          </h1>

          <div class="flex items-center">
            <Menu as="div" class="relative inline-block text-left">
              <div>
                <!-- <MenuButton
                  class="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900"
                >
                  Ordenar
                  <ChevronDownIcon
                    class="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                </MenuButton> -->
              </div>

              <transition
                enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
              >
                <MenuItems
                  class="absolute right-0 z-10 mt-2 w-40 origin-top-right rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none"
                >
                  <!-- <div class="py-1">
                    <MenuItem
                      v-for="option in sortOptions"
                      :key="option.name"
                      v-slot="{ active }"
                    >
                      <a
                        :href="option.href"
                        :class="[
                          option.current
                            ? 'font-medium text-gray-900'
                            : 'text-gray-500',
                          active ? 'bg-gray-100' : '',
                          'block px-4 py-2 text-sm',
                        ]"
                        >{{ option.name }}</a
                      >
                    </MenuItem>
                  </div> -->
                </MenuItems>
              </transition>
            </Menu>

            <!-- <button
              type="button"
              class="-m-2 ml-5 p-2 text-gray-400 hover:text-gray-500 sm:ml-7"
            >
              <span class="sr-only">View grid</span>
              <Squares2X2Icon class="h-5 w-5" aria-hidden="true" />
            </button> -->
            <button
              type="button"
              class="-m-2 ml-4 p-2 text-gray-400 hover:text-gray-500 sm:ml-6 lg:hidden"
              @click="mobileFiltersOpen = true"
            >
              <!-- <span class="sr-only">Filters</span> -->
              <FunnelIcon class="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </div>

        <section
          aria-labelledby="products-heading"
          class="min-h-500 pb-24 pt-6"
        >
          <h2 id="products-heading" class="sr-only">Products</h2>

          <div class="grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-4">
            <!-- Filters -->
            <form class="hidden min-h-500 bg-slate-50 py-5 pl-8 lg:block">
              <h3 class="sr-only">Categories</h3>
              <ul
                role="list"
                class="space-y-4 pb-6 text-sm font-medium text-gray-900"
              >
                <li v-for="category in subCategories" :key="category.name">
                  <a :href="category.href">{{ category.name }}</a>
                </li>
              </ul>

              <div
                v-for="section in filters"
                :key="section.id"
                as="div"
                class="py-6"
              >
                <h3 class="-my-3 flow-root">
                  <div
                    class="flex w-full items-center justify-between py-3 text-sm text-gray-400 hover:text-gray-500"
                  >
                    <span class="font-medium text-gray-700">{{
                      section.name
                    }}</span>
                  </div>
                </h3>
                <div class="pt-6">
                  <div class="space-y-4">
                    <div v-for="filter in filters" :key="filter.id">
                      <div
                        v-for="(option, optionIdx) in filter.options"
                        :key="option.value"
                        class="flex items-center space-y-1"
                      >
                        <input
                          :id="`filter-${section.id}-${optionIdx}`"
                          v-model="selectedCategories"
                          :name="`${section.id}[]`"
                          :value="option.value"
                          type="checkbox"
                          class="h-4 w-4 rounded border-gray-300 text-custom-calypso-fe3 focus:ring-custom-calypso-fe3"
                        />
                        <label
                          :for="`filter-${section.id}-${optionIdx}`"
                          class="ml-3 pb-1 text-sm text-gray-600"
                          >{{ option.label }}</label
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>

            <!-- Product grid -->
            <div class="lg:col-span-3">
              <!-- Your content -->
              <WebCouponGrid :coupons="coupons" />
            </div>
          </div>
        </section>
      </main>
    </div>
  </section>
</template>
<style scoped>
.font-lobster {
  font-family: "Lobster", cursive;
}
.font-montserrat {
  font-family: "Montserrat", sans-serif;
}
</style>
