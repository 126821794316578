<script setup>
definePageMeta({
  layout: "web",

  // meta: {
  //   "Cache-Control":
  //     "no-store, no-cache, must-revalidate, proxy-revalidate, max-age=0",
  //   Pragma: "no-cache",
  //   Expires: "0",
  // },
});

useHead({
  title: "Bienvenidos | Chicupón",
});

const route = useRoute();
const router = useRouter();
const { selectCouponsForWeb } = useCouponManager();
const { selectCategories } = useCategoryManager();
const { selectFeaturedBusinesses } = useBusinessManager();

const { data: categoriesData } = useAsyncData("detail-categories", async () => {
  const data = await selectCategories();
  return data;
});

const { data: businesses } = useAsyncData("featured_businesses", async () => {
  const data = await selectFeaturedBusinesses();
  return data;
});

const coupons = ref([]);
const selectedCategories = ref(
  typeof route.query.filters === "string"
    ? route.query.filters.split(",")
    : route.query.filters || [],
);

const fetchCoupons = async (categories) => {
  const validCategories = categories.filter((category) => category !== "");
  const data = await selectCouponsForWeb(
    validCategories.length ? validCategories : undefined,
  );
  if (data) {
    coupons.value = data;
  }
};

watchEffect(() => {
  const filters =
    typeof route.query.filters === "string"
      ? route.query.filters.split(",")
      : route.query.filters || [];
  if (filters.toString() !== selectedCategories.value.toString()) {
    selectedCategories.value = filters;
    fetchCoupons(filters);
  }
});

const updateCategories = (newCategories) => {
  if (newCategories.toString() !== selectedCategories.value.toString()) {
    selectedCategories.value = newCategories;
    router.push({
      path: route.path,
      query: { ...route.query, filters: selectedCategories.value.join(",") },
    });
  }
};

fetchCoupons(selectedCategories.value);
</script>

<template>
  <div>
    <CarouselSimple />
    <WebCatalogue
      :coupons="coupons"
      :categories="categoriesData"
      @update:selected-categories="updateCategories"
    />
    <WebLogoClouds :businesses="businesses" />
  </div>
</template>
